.Customers__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 12%;
  z-index: 3;
  /* z-index should be greater than the other containers */
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

}


.Customers__btn {
  color: white;
  background-color: #A6B1F7;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.Customers__btn:hover {
  background-color: #919EF6;
  color: white;
}


.principal__container {
  display: flex;
  /* Ajout de la propriété flex */
  flex-wrap: nowrap;
  /* Empêche le retour à la ligne */

  height: 100vh;
  color: white;
  width: 100%;
  position: absolute;
  top: 0;
  left: 12%;
  padding: 20px;

}

.FormCustomer,
.FormProduct {
  width: 100%;
  /* Ajout de la largeur de 100% pour chaque composant */
  margin-right: 20px;
  /* Ajout d'une marge à droite pour séparer les deux composants */
}

.principal__btn {
  background-color: white;
  color: #A6B1F7;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.principal__btn:hover {
  background-color: #919EF6;
  color: white;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: black;
}


.table-container {
  max-width: 100%;
  overflow-x: auto;
  border-color: #666;
  background-color: white;
}


.table-container table {
  table-layout: fixed;
  background-color: #0E0E0E;
  border-collapse: collapse;
  /* Collapse the borders for a cleaner look */
}

.table-container th,
.table-container td {
  padding: 10px;
  text-align: left;
  color: white;
  background-color: #666666;
}

/* Optional: Add styles for table header sticky behavior */
.table-container thead th {
  position: sticky;
  top: 0;
  background-color: #A6B1F7;
  font-weight: 500;
  z-index: 1;

}



/* Optional: Add custom scrollbar styles */
.table-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #888;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

thead {
  background-color: #A6B1F7;
  color: white;
}

th,
td {
  padding: 10px;
  text-align: left;
  color: black
}

th:first-child,
td:first-child {
  padding-left: 20px;
}

th:last-child,
td:last-child {
  padding-right: 20px;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

input[type="date"] {
  width: 100%;
  padding: 5px;
}

button {
  background-color: #A6B1F7;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

button:hover {
  background-color: #919EF6;
}