.Payment__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 12%;
  z-index: 3;
  /* z-index should be greater than the other containers */
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

}

.Payment__btn {
  color: white;
  background-color: #A6B1F7;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.Payment__btn:hover {
  background-color: #919EF6;
  color: white;
}


.table-container {
  max-width: 100%;
  overflow-x: auto;
  border-radius: 15px;
  overflow-y: auto;
  /* Add overflow-y property for vertical scroll */
  max-height: 400px;
  border-color: #666;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  background-color: #EEEDED;
}


.table-container table {
  table-layout: fixed;
  background-color: #EEEDED;
  border-collapse: collapse;

  /* Collapse the borders for a cleaner look */
}

.table-container th,
.table-container td {
  padding: 10px;
  text-align: center;
  color: black;
  font-size: 13px;
  background-color: #f9f9f9;

}



/* Optional: Add styles for table header sticky behavior */
.table-container thead th {
  /* position: sticky; */
  top: 0;
  font-size: 15px;
  background-color: #EEEDED;
  font-weight: 500;
  font-weight: bold;
  z-index: 1;

}

/* Optional: Add alternating row background colors */
.table-container tbody tr:nth-child(even) {
  background-color: #f9f9f9;

}

/* Optional: Add alternating row background colors */
.table-container tbody tr:nth-child(odd) {
  background-color: #f9f9f9;

}



/* Optional: Add custom scrollbar styles */
.table-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #888;
  ;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #919EF6;
}

input[type="date"] {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 10px;
  border-color: #f5f5f5;
  width: 60%;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease-in-out;
}

button {
  background-color: #A6B1F7;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 20px;
}

button:hover {
  background-color: #919EF6;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666666;
  z-index: 4;
}

.popup-content {
  background-color: #fff;
  max-width: 400px;
  padding: 20px;
  text-align: center;
}

.popup-content input {
  width: 30%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
  align-items: center;
  text-align: center;
}

.popup-content h2 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.popup-content p {
  font-size: 14px;
  margin-bottom: 20px;
}

.popup-buttons {
  display: flex;
  justify-content: center;
}

.popup-buttons button {
  margin: 0 5px;
}

.Scroll_bar {
  height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.SearchInput {
  color: black;
  border-color: #A6B1F7;
  border-radius: 5px;
}

.indicatorPresta {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.indicator-item {
  flex: 0 0 calc(25% - 10px);
  /* Ajustez la largeur en conséquence */
  margin: 5px;
  padding: 3px;

  box-sizing: border-box;
  text-align: center;
}

.red-text {
  color: red;
}

.search-bar-Name {
  margin-bottom: 40px;
}

.search-bar-Name input {
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  width: 80%;
  background-color: #EEEDED;
  transition: background-color 0.3s ease-in-out;
}

.search-bar-Name input:focus {
  outline: none;
  background-color: #f5f5f5;
}

.loading-container {
  display: flex;
  /* Ajoutez cette ligne pour activer le centrage horizontal */
  justify-content: center;
  /* Centre le contenu horizontalement */
  align-items: center;
  /* Centre le contenu verticalement */
  height: 20vh;
  /* Facultatif : définir la hauteur de la div parente pour centrer verticalement dans la fenêtre */

}

.loading-animation {
  color: black;
  font-weight: bold;
  text-align: center;
  /* Ajoutez cette ligne */
}

.Oval {
  align-items: right;
}

.Container-Paiement {
  width: 80%;
}

.Reminder {
  color: black;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #A6B1F7;
}

.EmailImage {
  width: 40px;
  /* Largeur souhaitée */
  height: auto;
  /* Laisser la hauteur ajustée automatiquement pour conserver les proportions de l'image */
  border-radius: 20px;
}