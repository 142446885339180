.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: transparent;
  padding: 30px;
  border-radius: 10px;

}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid #ccc;
  transition: border-bottom-color 0.2s ease-in-out;
}

.login__textBox:focus {
  outline: none;
  border-bottom-color: #A6B1F7;
}

.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: #A6B1F7;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-radius: 20px;
}

.login__btn:hover,
.back_home:hover {
  background-color: #919EF6;
}

.login__google {
  background-color: #fff;
  border: 2px solid #A6B1F7;
  color: #A6B1F7;
  font-weight: bold;
  border-radius: 20px;
}

.login__google:hover {
  background-color: #A6B1F7;
  color: white;
}

.login div {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

.back_home {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  border-radius: 50px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.back_home:hover {
  background-color: #919EF6;
  color: white
}

.login div a,
.back_home a {
  color: #A6B1F7;
  text-decoration: none;
}

.login div a:hover,
.back_home a:hover {
  text-decoration: underline;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}