.register {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
}

.register__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.register__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  border-bottom: 2px solid #D0D0D0;
  width: 100%;
}

.register__textBox:focus {
  outline: none;
  border-bottom-color: #919EF6;
}

.register__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  border-radius: 20px;
  color: #FFFFFF;
  background-color: #A6B1F7;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: 100%;
}

.register__btn:hover {
  background-color: #919EF6;
}

.register__google {
  background-color: #fff;
  border: 2px solid #A6B1F7;
  color: #A6B1F7;
  font-weight: bold;
  border-radius: 20px;
}

.register__google:hover {
  background-color: #919EF6;
}

.register div {
  margin-top: 15px;
  color: #666666;
}

.register div a {
  color: #A6B1F7;
  text-decoration: none;
}

.register div a:hover {
  text-decoration: underline;
}

.back_home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100px;
  color: #A6B1F7;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.back_home:hover {
  background-color: #A6B1F7;
  color: #FFFFFF;
}


.back_home a {
  color: #A6B1F7;
  text-decoration: none;
}

.back_home a:hover {
  text-decoration: underline;
  color: #FFFFFF;
}