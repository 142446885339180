/* Style pour le conteneur principal */
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  height: 150vh;
  max-width: 1000px;
  /* Ajustez la largeur maximale selon vos besoins */
  width: 100%;
  margin: 0 auto;
  /* Centre le conteneur horizontalement */
}

/* Style pour l'en-tête */
.dashboard-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  text-decoration: none;
  border-color: #A6B1F7;

}

.period-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background-color: #f9f9f9;

}

.period-button {
  background-color: #A6B1F7;
  color: white;
  border-radius: 20px;

  margin-right: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.period-button.active {
  background-color: #A6B1F7;
  margin-right: 20px;
  border-radius: 20px;
}

/* Style pour le conteneur des données */
.chart-container {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;

  width: 100%;
  margin: 0 auto;
  /* Centre le conteneur horizontalement */
}

.chart-container h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #666666;
}

.chart-container span {
  display: block;
  margin-bottom: 5px;
}

.chart-container span:last-child {
  margin-bottom: 0;
}

/* Style pour les boutons */


.button:hover {
  background-color: #919EF6;
}


.chart-container .indicator {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: center;
}

.chart-container .indicatorPresta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: center;
  background-color: white;
  padding: 10px;
}

.chart-container .indicator-item {
  width: calc(20% - 10px);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  display: inline-block;
  text-align: center;
  margin-right: 10px;

  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.2);
  /* ajoute une marge à droite entre chaque form input */
}

.chart-container .indicator-value {
  background-color: white;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 25px;
  color: white;
  background-color: #A6B1F7;
}

.chart-container .no-value {
  color: #666666;
}

/* Style pour les boutons */
.button {
  background-color: #A6B1F7;
  color: white;
  border: none;
  border-radius: 15px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.period-button {
  background-color: #0E0E0E;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.active {
  background-color: #A6B1F7;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

}

.button:hover {
  background-color: #919EF6;
}

.LastPeriode .indicator-value {
  background-color: #A6B1F7;
}

.LastPeriode {

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  background-color: white;
  padding: 20px;

}

.RollingPeriod {

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  background-color: white;
  padding: 20px;
}

.filter {
  margin-bottom: 20px;
}

.filter label {
  font-size: 18px;
  color: #333333;
  margin-right: 10px;
}

.filter select {
  padding: 8px;
  font-size: 16px;
  border-radius: 20px;
  border: 1px solid #ccc;

}

/* Responsive styles using media queries */
@media only screen and (max-width: 768px) {
  .dashboard-container {
    padding: 10px;
  }

  .period-buttons {
    flex-direction: column;
    align-items: center;
  }

  .period-button {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .chart-container .indicator-item {
    width: 100%;
    margin-right: 0;
  }

  .chart-container .indicator-value {
    margin-top: 10px;
  }

  .LastPeriode,
  .RollingPeriod,
  .container {
    width: 80%;
    margin-left: 20%;
  }

  h1 {
    font-size: 15px;
  }
}