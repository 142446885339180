/* styles for the form container */

header {
  background: #A6B1F7;
  padding: 1rem 5vw;
}


.form__container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  font-size: 30px;
  margin: 0 auto;
  color: #666666;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}



.form__container_Goal {
  background-color: #f9f9f9;
  padding: 20px;

  width: 100%;
  font-size: 30px;
  margin: 0 auto;
  color: #666666;
  font-weight: bold;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}


.form__container:hover {

  transition: all 0.5s ease-in-out;
  background-color: white;
  color: #0E0E0E;
  padding: 30px;
  border-radius: 0px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.form__container:hover h1 {
  color: black;
}

.form__container:hover button.form__submit {
  background-color: #A6B1F7;
  color: white;
}


.form__container:hover button.SpecialButton:hover {
  color: white;
  background-color: #A6B1F7;
  border-color: #A6B1F7;
  border: 2px solid #A6B1F7;
}

.form__container button.SpecialButton {
  background-color: white;
  color: #A6B1F7;
  border-color: #A6B1F7;
  border-radius: 15px;
  border: 2px solid #A6B1F7;
  text-decoration: bold;
}


.form__container:hover button.form__submit:hover {
  background-color: #919EF6;
  color: white;
}


.Form__btn {
  background-color: black;
  color: #A6B1F7;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.Form__btn:hover {
  background-color: #919EF6;
  color: black;
}



button.form__submit {

  background-color: #f9f9f9;
  color: black;
  border-radius: 20px;
  font-weight: bold;
}

.select_input {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-left: 10px;
  padding: 15px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
}

.success-message {
  background-color: #A6B1F7;
  color: white;
  padding: 10px;
  margin: 25px;
  border-radius: 5px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: black
}

.form__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 20px;
  color: black
}

.form__input {
  margin-left: 10px;
  padding: 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  width: 70%;
  height: 10px;

  /* background-color:black;
  color:red; */
  margin-bottom: 20px;
}


.form__label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}


.form__input-field {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;
}

.form__textarea-field {
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  width: 80%;
  height: 50px;
  margin-bottom: 20px;
}

.form__row {
  display: inline-block;
  margin-right: 10px;
  /* ajoute une marge à droite entre chaque form input */
}

.form__submit {
  display: inline-block;
  background-color: #A6B1F7;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  overflow-x: auto;
}

.form__submit:hover {
  background-color: #919EF6;
}

.objectifs {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.montant {
  font-size: 1.5rem;
  color: #A6B1F7;
  margin: 0 0.2rem;
}

.chart-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  color: #A6B1F7;
}

.chart-wrapper {
  width: 30%;
  padding: 10px;
  box-sizing: border-box;
}

.chart-wrapper canvas {
  max-height: 400px;
}

li {
  color: #666;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  /* Add overflow-y property for vertical scroll */
  max-height: 200px;
  border-color: #666;
  background-color: white;
}


.table-container table {
  table-layout: fixed;
  background-color: #0E0E0E;
  border-collapse: collapse;
  /* Collapse the borders for a cleaner look */
}

.table-container th,
.table-container td {
  padding: 10px;
  text-align: left;
  color: white;
  font-size: 13px;
  background-color: #666666;
}

/* Optional: Add styles for table header sticky behavior */
.table-container thead th {
  position: sticky;
  top: 0;
  background-color: #0E0E0E;
  font-weight: 500;
  z-index: 1;

}

/* Optional: Add alternating row background colors */
.table-container tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Optional: Add hover effect to table rows */
.table-container tbody tr:hover {
  background-color: #eaf6ff;
}

/* Optional: Add custom scrollbar styles */
.table-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #888;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.KpisGoalsData {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  border-radius: 20px;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #667;
}

.KpisGoalsData span {

  font-weight: bold;
  color: #0E0E0E;
}

.kpis-container span {
  color: #0E0E0E;
}

.column {
  width: 38%;
  /* Adjust the width as needed */
}

.payment-option-btn {
  /* Styles par défaut pour le bouton */
  border: none;
  background-color: transparent;
  color: #888;
  /* Couleur bleue utilisée par défaut sur iOS */
  font-size: 16px;
  font-weight: bold;
  padding: 12px 20px;
  border-radius: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Autres styles que vous souhaitez appliquer */
}

.payment-option-btn.active {
  /* Styles pour le bouton activé */
  background-color: #A6B1F7;
  color: white;
}

.payment-option-btn:hover {
  /* Styles pour le survol du bouton */
  background-color: #A6B1F7;
  color: white;
}

.payment-option-btn:active {
  /* Styles pour le clic sur le bouton */
  transform: scale(0.95);
}

.SearchInput {
  color: black;
  border-color: #A6B1F7;
  border-radius: 5px;

}

.retractation-item {
  max-width: 100%;
  max-height: 200px;
  background-color: #EEEDED;
  font-size: 16px;
  color: black;
  padding: 20px;
  cursor: pointer;
}

.retractation-list {
  overflow-x: auto;
  overflow-y: auto;
  color: black;
  text-decoration: none;
  border-radius: 20px;
  /* Add overflow-y property for vertical scroll */
}

.retractation-list span {
  overflow-x: auto;
  overflow-y: auto;
  color: black;
  text-decoration: none;
  border-radius: 20px;
  font-weight: normal;
  /* Add overflow-y property for vertical scroll */
}

.popup-content {
  font-size: 16px;
}

.indicators_goal {
  background-color: #A6B1F7;
}

.GoalsData {
  color: #A6B1F7;
}

.search-box {
  margin-bottom: 40px;
}

.search-box input {
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 30px;
  border: none;
  width: 80%;
  background-color: #EEEDED;
  transition: background-color 0.3s ease-in-out;
}

.search-box input:focus {
  outline: none;
  background-color: #f5f5f5;
}

/* Add this media query to adjust styles for smaller screens (phones) */
@media only screen and (max-width: 767px) {
  .chart-container {
    flex-direction: column;
    /* Change to a column layout on smaller screens */
    align-items: center;
    /* Center charts on smaller screens */
  }

  .chart-wrapper {
    width: 80%;
    /* Adjust the width as needed */
    margin-bottom: 20px;
    /* Add space between charts on smaller screens */
  }

  .chart-wrapper canvas {
    max-height: 300px;
    /* Adjust the max height of the chart canvas */
  }

  .objectifs {
    width: 100%;
    margin-left: 20%;
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }

  .objectifs p {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
  }

  .Rappel p {
    font-size: 1.2rem;
    font-weight: bold;

    margin-left: 0;
  }


  .Rappel {
    width: 100%;
    margin-left: 20%;
  }

  h2 {
    font-size: 18px;
    margin-left: 20%;
  }

  /* Add any other styles needed for smaller screens */
}