/* styles for the footer container */

.footer {
    background: transparent;
    padding: 1rem 5vw;
    color: black;
    width: 100%;
    margin-top: 50px;
}

.footer__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* Align les éléments en haut */
}

.footer__navigation {
    flex: 1;
    margin-right: 1rem;
    display: flex;
    align-items: center;
}

.footer__navigation h3 {
    font-size: 0.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.footer__navigation ul {
    list-style: none;
    padding: 0;
}

.footer__navigation li {
    margin-bottom: 0.5rem;
    list-style-type: none;
}

.footer__nav-section h3 {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.footer__nav-section a {
    font-size: 0.7rem;
    list-style-type: none;
}

.footer__nav-section p {
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
}

.footer__nav-freepik {
    margin-bottom: 0.3rem;
    font-size: 0.7rem;
    align-items: center;
    color: black;
    text-decoration: none;
}

.footer__social {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.footer__social a {
    color: white;
    text-decoration: none;
    font-size: 0.7rem;
    margin-left: 1rem;
}

.footer__social i {
    font-size: 1rem;
}

.footer__copyright {
    flex: 1;
    text-align: center;
    margin-top: 1rem;
    font-size: 1rem;
}


.footer__nav-section {
    flex: 1;
    margin-right: 1rem;

    /* Définissez la hauteur maximale souhaitée */
    overflow-y: auto;
    /* Ajoutez une barre de défilement si nécessaire */
}

.footer__container a:link {
    color: #A6B1F7;
    text-decoration: none;
}

.footer__container a:link {
    color: #A6B1F7;
    text-decoration: none;
}

.tuto {
    color: #A6B1F7;
    text-decoration: none;
    cursor: pointer;
}

.footer__nav-freepik {
    color: #A6B1F7;
    text-decoration: none;
    cursor: pointer;
}

/* Applique un style aux liens visités dans le pied de page */
.footer__container a:visited {
    color: #A6B1F7;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .footer {
        margin-top: auto;
        margin-bottom: 0px;
    }
}