.Goals__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 12%;
  z-index: 3;
  /* z-index should be greater than the other containers */
  background-color: #f9f9f9;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

}

.Goals__btn {
  color: white;
  background-color: #A6B1F7;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.Goals__btn:hover {
  background-color: #919EF6;
  color: black;
}


.principal__container {
  background-color: black;
  height: 100vh;
  /* cela définit la hauteur de l'élément sur 100% de la hauteur de la fenêtre du navigateur */
  color: white;
  width: 100%;
  position: absolute;
  top: 0;
  left: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

}

.principal__btn {
  background-color: white;
  color: #A6B1F7;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.principal__btn:hover {
  background-color: #919EF6;
  color: white;
}

h1 {
  color: black
}


.chart-container {
  background-color: #f9f9f9;
  padding: 10px;
  width: 100%;
  margin: auto;

}

button.form__submit {
  background-color: #A6B1F7;
  color: white;
}