/* NotPremiumPage.css */
.not-premium-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160vh;
  background-color: #f9f9f9;
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.BackHomeButton {
  opacity: 70%;

}

p {
  font-size: 16px;
  margin-bottom: 30px;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button-container a {
  text-decoration: none;
  color: #fff;
  background-color: #A6B1F7;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button-container a:hover {
  background-color: #919EF6;
  color: #fff;
}

.Section__image__NotPremium {
  background-color: white;
  width: 50%;
  margin: 0 auto;
  /* Ajoutez cette ligne pour centrer horizontalement */
  padding: 20px;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* Ajoutez un peu de marge intérieure pour l'image */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  /* Ajoutez une ombre autour de l'image */
  border-radius: 10px;
  /* Ajoutez des coins arrondis */
}

.PrevisionImage {
  width: 80%;
  /* Assurez-vous que l'image occupe 100% de la largeur du conteneur */
  max-width: 80%;
  /* Ajoutez cette ligne pour assurer une taille maximale de l'image */
}

/* Responsive styles using media queries */
@media only screen and (max-width: 768px) {

  h1 {
    font-size: 18px;
  }
}