.menu__container {
    position: fixed;
    top: 15%;
    bottom: 0;
    left: 0;
    width: 20%;
    background-color: transparent;
    color: black;
    padding: 10px;
    z-index: 1;
}

.menu__container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu__container li {
    padding: 20px;
}

.menu__container a {
    color: black;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

.menu__container a:hover {
    background-color: white;
    /* transition: all 0.3s ease-in-out; */
    padding: 10px;
    margin: 10px;
    border-radius: 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    color: #A6B1F7;
    border-left: 3px solid #A6B1F7;
}

.MenuActive {
    background-color: white;
    transition: all 0.3s ease-in-out;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    color: #A6B1F7;
    border-left: 3px solid #A6B1F7;
    max-width: 200px;

}

.MenuTitle {
    color: black;
    font-weight: bold;
    text-decoration: none;
}