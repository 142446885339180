.home {
  color: black;
  font-family: sans-serif;
  padding: 50px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  background-color: transparent;
}

.text-container {
  width: 70%;
  /* Ajustez la largeur du texte-container selon vos besoins */
  border-radius: 20px;
  background-color: transparent;
  flex: 2;
}


h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  background-color: transparent;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #667
}

h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.one {
  margin-bottom: 20px;
}

ul {
  padding-left: 20px;
}

.home__buttons {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.home__button {
  background-color: #A6B1F7;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  transition: background-position .5s ease-in-out .1s, color .5s ease 0s, background-color .5s ease;
}

.home__button:hover {
  background-color: #919EF6;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 20px;
  margin-top: 40px;

}

.image-container {
  flex: 1;
  border-radius: 50px;
  background-color: white;
  margin-right: 5px;
}

.logo {
  border-radius: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.logo2 {
  border-radius: 50px;
  width: 100px;
  align-items: center;
}

.test-image {
  width: 50%;
  max-width: 50%;
  height: auto;
}

.one {
  justify-content: center;
}

.two {
  background-color: transparent;
}

video {
  max-width: 50%;
  border-radius: 50px;

}

.video-container {
  border-radius: 50px;
}

@media only screen and (max-width: 768px) {

  h3 {
    font-size: 18px;
  }

  video {
    max-width: 100%;

  }
}