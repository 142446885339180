.dashboard {
  height: 150vh;
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 30px;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  color: black;
}


.dashboard__container {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  /* z-index should be greater than the other containers */
  background-color: transparent;
  color: #A6B1F7;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;

}

.dashboard__btn {
  position: absolute;
  top: 30px;
  left: 20px;
  padding: 10px;
  font-size: 16px;
  /* margin-top: 5px; */
  border: none;
  color: white;
  background-color: #A6B1F7;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-radius: 5px;
}

.dashboard__btn:hover {
  background-color: #919EF6;
}

.dashboard div {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

.dashboard div a {
  color: #a4bcb0;
  text-decoration: none;
}

.dashboard div a:hover {
  text-decoration: underline;
}

.dashboard__container {
  margin-right: 20px;
  align-items: center;
  padding: 30px;
}

.Section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #f9f9f9;
  margin-bottom: 50px;
  padding: 20px;
}



.Section:hover {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  flex-wrap: wrap;
}

.Section__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 10px;
  width: calc(100% / 3);
  margin: 10px;
  transition: 0.5s ease-in-out;
}

.Section__container:hover {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 80px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: calc(100% / 3 - 20px);
  margin: 10px;
  background-color: white;
}

.dashboard__tutorial-btn {
  display: block;
  margin: auto;
  text-align: center;
}

.Section__container:hover button {

  background-color: white;
  color: #ff3366;
  text-decoration: underline;
}


.Section__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 2px solid #ccc;
  transition: border-bottom-color 0.2s ease-in-out;
}

.Section__textBox:focus {
  outline: none;
  border-bottom-color: #a4bcb0;
}

.Section__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: #ff3366;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.2s ease-in-out;
  border-radius: 5px;
}

.Section__btn:hover {
  background-color: #619f81;
}

.Section div {
  margin-top: 20px;
  font-size: 14px;
  color: #666;
}

.Section div a {
  color: #a4bcb0;
  text-decoration: none;
}

.Section div a:hover {
  text-decoration: underline;
}

.logo {
  position: absolute;
  top: 0;
  right: 0;
  width: 110px;
  /* ajuster la taille en fonction de vos besoins */
  height: auto;
}

.Section__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Section__image img {
  width: 200px;
  /* Ajustez la taille selon vos besoins */
  height: 150px;
  cursor: pointer;
}

.logout-btn {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 10px;
}

.infoImage {
  width: 25px;
  /* Largeur souhaitée */
  height: auto;
  /* Laisser la hauteur ajustée automatiquement pour conserver les proportions de l'image */
}

@media only screen and (max-width: 768px) {
  .dashboard {
    padding: 15px;
    height: 2300px;

  }

  .dashboard__btn {
    top: 15px;
    left: 15px;
    font-size: 14px;
  }

  .Section__container {
    width: calc(80% - 20px);
    margin: 10px;
  }

  .FooterSection {
    margin-top: auto;
    margin-bottom: 0px;
  }

  .Section__image img {
    width: 150px;
    /* Ajustez la taille selon vos besoins */
    height: 120px;
    cursor: pointer;
  }
}