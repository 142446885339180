.indicator-definitions-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.indicator-definition {
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.indicator-definition h2 {
    color: #A6B1F7;
}

.indicator-definition p {
    margin-top: 10px;
    line-height: 1.5;
}