.reset {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: transparent;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.reset__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 20px;
    border: none;
    border-bottom: 2px solid #ccc;
    transition: border-bottom-color 0.2s ease-in-out;
}

.reset__textBox:focus {
    outline: none;
    border-bottom-color: #A6B1F7;
}

.reset__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: #A6B1F7;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    border-radius: 5px;
}

.reset__btn:hover {
    background-color: #919EF6;
}

.reset div {
    margin-top: 20px;
    font-size: 14px;
    color: #666;
}

.reset div a {
    color: #A6B1F7;
    text-decoration: none;
}

.reset div a:hover {
    text-decoration: underline;
}