.principal__container {
  background-color: #f9f9f9;
  height: 100vh;
  /* cela définit la hauteur de l'élément sur 100% de la hauteur de la fenêtre du navigateur */
  color: black;
  width: 85%;
  position: flex;
  top: 0;
  left: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 20px;
}

.principal__btn {
  background-color: white;
  color: #A6B1F7;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.principal__btn:hover {
  background-color: #a4bcb0;
  color: white;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  border-radius: 0px;
}

.containerPrevision {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
}

.form__container h2 {
  margin-bottom: 10px;
  color: #333;
}


.form__container {
  margin-bottom: 20px;
  text-align: center;
  margin-right: 15px;
}

.indicator {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: center;
}

.indicator-item {
  width: calc(35% - 2px);
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 0px;
  color: #666666;
  background-color: white;
  display: inline-block;
  text-align: center;
  margin-right: 20px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);

  /* ajoute une marge à droite entre chaque form input */
}

.indicator-value {
  font-size: 30px;
  border-radius: 10px;
  color: #667;
  margin-top: 20px;
  background-color: white;
}

h1 {
  background-color: transparent;
  color: black
}

.Infos {
  color: #666;
  font-size: 10px;
  font-style: italic;
}

.Section__image__Prevision {
  height: 100vh;
  /* cela définit la hauteur de l'élément sur 100% de la hauteur de la fenêtre du navigateur */
  color: black;
  width: 85%;
  align-items: center;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* Add this line to stack the items vertically */

}

.Section__image__Prevision img {
  width: auto;
  /* Ajustez la taille selon vos besoins */
  height: 340px;
}

.loading-animation {
  display: flex;
  /* Utilisez flexbox pour centrer horizontalement */
  justify-content: center;
  /* Centrez horizontalement le contenu */
  align-items: center;
}

.containerPrevisionGlobal {
  width: 800px;
  /* Personnalisez la largeur */
  height: 400px;
  /* Personnalisez la hauteur */
}

/* Responsive styles using media queries */
@media only screen and (max-width: 768px) {

  .principal__container {
    width: 80%;
    margin-left: 10%;
  }

  .container,
  .containerPrevision,
  .indicator {
    flex-direction: column;
  }

  .indicator-item {
    width: 100%;
    margin-right: 0;
  }

  .Section__image__Prevision {
    width: 100%;
    padding: 10px;
    /* Add padding for better spacing on smaller screens */
  }

  .Section__image__Prevision img {
    width: 100%;
    /* Make the image take full width */
    height: auto;
    /* Maintain aspect ratio */
    max-width: 400px;
    /* Limit maximum width */
  }

  .analysis-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .analysis-buttons button {
    width: 100%;
    margin-bottom: 10px;
  }

  .Footer {
    text-align: center;
  }

  .containerPrevisionGlobal {
    width: 100%;
    padding: 10px;
    /* Ajoutez un peu de marge sur les côtés pour les écrans plus petits */
  }

  /* Ajoutez des styles spécifiques pour la version mobile de votre graphique */
  .containerPrevisionGlobal canvas {
    max-width: 100%;
    height: 400px;
    /* Ajustez la hauteur du graphique selon vos besoins */
  }

  /* Ajoutez des styles pour le texte des légendes du graphique pour les écrans plus petits */
  .containerPrevisionGlobal .chartjs-text {
    font-size: 12px;
  }

  /* Ajoutez des styles pour l'espacement entre les barres du graphique sur les écrans plus petits */
  .containerPrevisionGlobal .bar-chart .bar-container {
    margin-right: 4px;
    margin-left: 4px;
  }
}