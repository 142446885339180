/* Reset some default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

header {
    background: #A6B1F7;
    padding: 1rem 5vw;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
}

.Principal_container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px;

}

h2 {
    color: black;
    margin-bottom: 15px;
}

.form__container {
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}



.form__label {
    display: block;
    margin-bottom: 5px;
    color: black;
}

.form__input {
    width: 80%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.form__input:focus {
    border-color: #A6B1F7;
}

input[type="date"] {
    padding: 15px;
    font-size: 14px;
    border-radius: 10px;
    border-color: #f5f5f5;
    border: 1px solid #ccc;
    width: 90%;
    background-color: #f9f9f9;
    transition: background-color 0.3s ease-in-out;
}

.form__submit {
    background-color: #A6B1F7;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form__submit:hover {
    background-color: #7F8BCC;
}

.chart-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.chart-wrapper {
    width: 30%;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.objectifs {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;

}

.montant {
    color: #A6B1F7;
    font-weight: bold;
    font-size: 18px;
}

.Rappel {
    margin-top: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

label {
    font-weight: bold;
}