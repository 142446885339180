/* Style pour le conteneur principal */
.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #f9f9f9;
    min-height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
}

/* Style pour l'en-tête */
.dashboard-header {
    text-align: center;
    margin-bottom: 40px;
}

.dashboard-title {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
}

/* Style pour la barre de recherche */
.search-bar {
    margin-bottom: 40px;
}

.search-bar input {
    padding: 12px 50px;
    font-size: 16px;
    border-radius: 30px;
    border: none;
    width: 100%;
    background-color: #e0e0e0;
    transition: background-color 0.3s ease-in-out;
}

.search-bar input:focus {
    outline: none;
    background-color: #f5f5f5;
}

/* Style pour les sections de la FAQ */
.faq-sections {
    width: 100%;
}

.faq-section {
    margin-bottom: 40px;
    background-color: white;
    padding: 30px;
    border-radius: 0px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
}

.faq-section:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.faq-section h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
    text-decoration: none;
}

.faq-section p {
    font-size: 18px;
    color: #666666;
    line-height: 1.5;
}

.faq-section a {
    color: #A6B1F7;
    text-decoration: none;
    border-bottom: 1px solid #A6B1F7;
    transition: border-bottom 0.3s ease-in-out;
}

.faq-section a:hover {
    border-bottom: 1px solid transparent;
}

/* Style pour les boutons de lien */
.button-link {
    background-color: #A6B1F7;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 12px 30px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    text-decoration: none;
}

.button-link:hover {
    background-color: #919EF6;
}

.search-bar input::placeholder {
    text-align: center;
    /* Centre le texte du placeholder horizontalement */
}

.FAQImage {
    width: 250px;
    /* Largeur souhaitée */
    height: auto;
    /* Laisser la hauteur ajustée automatiquement pour conserver les proportions de l'image */
    border-radius: 50px;
}