.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
}

.graph-item {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
}